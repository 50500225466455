import React from "react";
import './Settings.css';
import { useState, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import { MdOutlineCancel, MdCheckCircleOutline, MdCrop } from "react-icons/md";

function Settings() {
  const [menu, setMenu] = useState(0);
  const [change, setChange] = useState(0);

  const [cs, setCS] = useState("");
  const [csIP, setCSIP] = useState("");
  const [csPort, setCSPort] = useState("");

  function sleep(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function changeApply(val: number) {
    if (val === 1) {
      // accept
      if (menu === 1) {
        await fetch("http://127.0.0.1:4545/cs_write?TYPE=" + cs + "&IP=" + csIP + "&PORT=" + csPort).then(res => { }, err => { });
      }
    }else{
      // cancel
    }
    loadSettings(menu);
    setMenu(0);
    setChange(0);
    document.documentElement.style.setProperty("--settingsMenuDisplay", "visible");
  }

  function changeDone() {
    document.documentElement.style.setProperty("--settingsMenuDisplay", "hidden");
    setChange(1);
    console.log("done");
  }

  async function changeCS(event: any) {
    changeDone();
    setCS(event.target.value);
    let cs = event.target.value; // locally overwrite the global cs variable because it updates delayed
    if (cs === "cs2" || cs === "cs3") {
      setCSIP("192.168.");
      setCSPort("15731");
    }else if (cs === "z21") {
      setCSIP("192.168.");
      setCSPort("21105");
    }else if (cs === "dr5000z21") {
      setCSIP("192.168.");
      setCSPort("21105");
    }else if (cs === "ecos") {
      setCSIP("192.168.");
      setCSPort("15471");
    }else{
      setCSIP("?");
      setCSPort("?");
    }
  }

  async function changeCSIP(event: any) {
    changeDone();
    setCSIP(event.target.value);
  }

  async function changeCSPort(event: any) {
    changeDone();
    setCSPort(event.target.value);
  }

  async function loadSettings(val: number) {
    if (val === 0 || val === 1) {
      await fetch("http://127.0.0.1:4545/cs_read.json").then(response => response.json()).then(res => {
        setCS(res.type);
        let ip1 = Math.floor(res.ip/(256*256*256));
        let ip2 = Math.floor(res.ip/(256*256))%256;
        let ip3 = Math.floor(res.ip/(256))%256;
        let ip4 = Math.floor(res.ip)%256;
        setCSIP(ip1.toString()+"."+ip2.toString()+"."+ip3.toString()+"."+ip4.toString());
        setCSPort(res.port);
      });
    }
  }

  useEffect(() => {
    console.log("settings loaded");
    loadSettings(0);
    setChange(0);
    document.documentElement.style.setProperty("--settingsMenuDisplay", "visible");
  }, []);

  return (
    <div className="settingsContainer">
      <div></div>
      <div>
        <h3>Sections</h3>
        <div className={(menu === 1)?'settingsMenuSel':'settingsMenu'} onClick={(e) => setMenu(1)}><b>Command station</b></div>
          {((menu !== 0) && (change !== 0)) &&
            <div>
                <div className="settingsMenuEndItem" onClick={() => changeApply(0)}>
                  <div>
                    <IconContext.Provider value={{size: '26px'}} >
                      <MdOutlineCancel />
                    </IconContext.Provider>
                  </div>
                  <div style={{margin: "4px"}}>Cancel</div>
                </div>
                <div className="settingsMenuEndItem" onClick={() => changeApply(1)}>
                  <div>
                    <IconContext.Provider value={{size: '26px'}} >
                      <MdCheckCircleOutline />
                    </IconContext.Provider>
                  </div>
                  <div style={{margin: "4px"}}>Accept</div>
                </div>
            </div>
          }
      </div>
      <div className="settingsMenuContainer">
        <h3>Parameters</h3>
        <div className="settingsMenuPadding">
          {(menu === 0) &&
            <div>
              <p>Choose a section from the left menu.</p>
            </div>
          }
          {(menu === 1) &&
              <div>
              <p>
                <label>Command station: </label>
                <select id="cs" onChange={(e) => changeCS(e)} value={cs}>
                  <option value="">Select...</option>
                  <option value="cs2">Märklin CS2</option>
                  <option value="cs3">Märklin CS3</option>
                  <option value="ecos">ESU Ecos</option>
                  <option value="z21">Roco Z21</option>
                  <option value="dr5000z21">Digikeijs DR5000 (Z21 protocol)</option>
                </select>
              </p>
              {(cs === "cs2" || cs === "cs3" || cs === "z21" || cs === "dr5000z21" || cs === "ecos") &&
              <div>
                <p>
                  <label>IP address: </label>
                  <input type="text" value={csIP} onChange={(e) => changeCSIP(e)}></input>
                </p>
                <p>
                  <label>Port: </label>
                  <input type="text" value={csPort} onChange={(e) => changeCSPort(e)}></input>
                </p>
              </div>
              }
            </div>
          }
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default Settings;
