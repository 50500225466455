import React from "react"
import Icon from './Icon'
import { IconContext } from "react-icons";
import { MdOutlineCancel, MdCheckCircleOutline } from "react-icons/md";

export class Tile extends React.Component {

  draw: number;
  draw_old: number;
  type: number;
  indicator: number;
  rotation: number;
  mode: number;
  modal: number;
  items: number[];
  items_dir: number[];
  addr: number;
  addr_backup: number;
  type_backup: number;
  rotation_backup: number;
  color: number[];
  flash: number[];
  light: number;
  ID: number;

  constructor(props: any) {
    super(props);
    this.draw = 0;
    this.draw_old = 0;
    this.type = 100;
    this.indicator = 0;
    this.rotation = 0;
    this.mode = 0;
    this.modal = 0;
    this.items = [100, 101, 200, 203, 300, 301, 302, 303, 400, 401, 402, 403, 500, 600, 700, 800, 900];
    this.items_dir = [201, 202];
    this.addr = 0;
    this.addr_backup = 0;
    this.type_backup = 0;
    this.rotation_backup = 0;
    this.color = [0, 0, 0, 0, 0, 0, 0, 0];
    this.flash = [0, 0, 0, 0, 0, 0, 0, 0];
    this.light = 0;
    this.ID = 0;
  }

  setup(id: number, draw: number, type: number, rotation: number, addr: number, indicator: number) {
    this.ID = id;
    this.draw = draw;
    this.draw_old = draw;
    this.type = type;
    this.rotation = rotation;
    this.addr = addr;
    this.setMode(this.mode);
    this.indicator = indicator;
    //this.forceUpdate();
  }

  async drawingButton(val: number) {
    if (this.mode === 100 && (this.type % 100) === 0) {
      // draw
      if (val >= 0 && val <= 7) {
        this.draw ^= (1 << val);
      }
      this.forceUpdate();
    } else if (this.mode === 103) {
      // erase
      if (this.type%100 !== 0) {
        this.type = Math.floor(this.type/100)*100;
        this.indicator = 0;
        this.addr = 0;
        this.rotation = 0;
        this.draw_old = 0;
      }else{
        this.type = 100;
        this.draw = 0;
        this.indicator = 0;
      }
      //fetch("http://127.0.0.1:4545/turnout_set_type?ID=" + this.ID + "&TYPE=" + this.type + "&ADD=" + (+this.rotation + (this.indicator*4))).then(res => { }, err => { });
      this.setMode(this.mode);
      console.log("erase: type "+this.type+" draw "+this.draw);
      this.forceUpdate();
    } else if (this.mode === 200) {
      if (this.modal === 0 && this.type !== 0) {
        // open modal window
        console.log("open modal");
        this.addr_backup = this.addr;
        this.type_backup = this.type;
        this.rotation_backup = this.rotation;
        this.modal = 1;
        this.forceUpdate();
      }
    } else if (this.mode === 205) {
      // change indicator type
      switch (this.type) {
        case 100: break;
        case 101: break;
        case 300: break;
        case 400: break;
        case 600:
          if (this.indicator === 0) {
            this.indicator = 1;
            this.rotation %= 2;
          }else if (this.indicator === 1 && (this.rotation < 2)) {
            this.rotation += 2;
          }else if (this.indicator === 1) {
            this.indicator = 2;
            this.rotation %= 2;
          }else{
            this.indicator = 0;
          }
          break;
        case 900: break;
        default:
          if (this.indicator) {
            this.indicator = 0;
          }else{
            this.indicator = 1;
          }
          break;
      }
      fetch("http://127.0.0.1:4545/turnout_set_type?ID=" + this.ID + "&TYPE=" + this.type + "&ADD=" + (+this.rotation + (this.indicator*4))).then(res => { }, err => { });
      this.forceUpdate();
    } else if (this.mode === 0) {
      // toggle turnout (debug only?)
      // only call if the tile has a button
      if (this.type === 201 || this.type === 202 || this.type === 302 || this.type === 303 || this.type === 402 || this.type === 403) {
        fetch("http://127.0.0.1:4545/toggle?ID=" + this.ID).then(res => { }, err => { });
      }
    } else if (this.mode === 204) {
      // invert turnout
      fetch("http://127.0.0.1:4545/turnout_invert?ID=" + this.ID).then(res => { }, err => { });
      this.forceUpdate();
    }
  }

  ledUpdate(led: number, color: number, flash: number) {
    if (led < 8) {
      this.color[led] = color;
      this.flash[led] = flash;
    }
  }

  ledPhase(val: number) {
    let new_light = this.light;
    for (let n = 0; n < 8; n++) {
      if (val === 1) {
        if (this.flash[n] === 2) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 3) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 4) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 0) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 1) {
          new_light |= 1 << n;
        }
      }
      if (val === 2) {
        if (this.flash[n] === 3) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 4) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 1) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 0) {
          new_light &= ~(1 << n);
        }
      }
      if (val === 3) {
        if (this.flash[n] === 2) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 3) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 4) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 0) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 1) {
          new_light |= 1 << n;
        }
      }
      if (val === 4) {
        if (this.flash[n] === 3) {
          new_light &= ~(1 << n);
        }
        if (this.flash[n] === 4) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 1) {
          new_light |= 1 << n;
        }
        if (this.flash[n] === 0) {
          new_light &= ~(1 << n);
        }
      }
    }
    if (new_light !== this.light) {
      this.light = new_light;
      this.forceUpdate();
    }
  }

  setMode(val: number) {
    if (this.draw !== this.draw_old) {
      if ((this.type % 100) === 0) {
        this.type = 0;
        if (this.draw === 0x00) { this.type = 100; }
        if (this.draw === 0x11 || this.draw === 0x44) { this.type = 200; }
        if (this.draw === 0x13 || this.draw === 0xC4 || this.draw === 0x31 || this.draw === 0x4C) { this.type = 300; }
        if (this.draw === 0x91 || this.draw === 0x64 || this.draw === 0x19 || this.draw === 0x46) { this.type = 400; }
        if (this.draw === 0x02 || this.draw === 0x80 || this.draw === 0x20 || this.draw === 0x08) { this.type = 500; }
        if (this.draw === 0x22 || this.draw === 0x88) { this.type = 600; }
        if (this.draw === 0x93 || this.draw === 0xE4 || this.draw === 0x39 || this.draw === 0x4E) { this.type = 700; }
        if (this.draw === 0x82 || this.draw === 0xA0 || this.draw === 0x28 || this.draw === 0x0A) { this.type = 800; }
        if (this.draw === 0x01 || this.draw === 0x04 || this.draw === 0x10 || this.draw === 0x40) { this.type = 900; }
        if (this.type === 600) {
          if (this.draw === 0x22 && (this.rotation === 1 || this.rotation === 3)) {
            this.rotation = 0;
          }
          if (this.draw === 0x88 && (this.rotation === 0 || this.rotation === 2)) {
            this.rotation = 1;
          }
        }else{
          this.rotation = 0;
          if (this.draw === 0x44 || this.draw === 0x4C || this.draw === 0x46 || this.draw === 0x08 || this.draw === 0x88 || this.draw === 0x4E || this.draw === 0x0A || this.draw === 0x04) { this.rotation = 1; }
          if (this.draw === 0xC4 || this.draw === 0x64 || this.draw === 0x80 || this.draw === 0xE4 || this.draw === 0xA0 || this.draw === 0x40) { this.rotation = 3; }
          if (this.draw === 0x31 || this.draw === 0x19 || this.draw === 0x20 || this.draw === 0x39 || this.draw === 0x28 || this.draw === 0x10) { this.rotation = 2; }
        }
      }
      this.draw_old = this.draw;
      this.mode = val;
      fetch("http://127.0.0.1:4545/turnout_set_type?ID=" + this.ID + "&TYPE=" + this.type + "&ADD=" + this.rotation).then(res => { }, err => { });
      this.forceUpdate();
      return this.draw;
    }else{
      this.mode = val;
      this.forceUpdate();
      return -1;
    }
  }

  render() {
    if (this.modal === 0) {
      return (
        <div>
          <div><Icon mode={this.mode} draw={this.draw} type={this.type} rotation={this.rotation} color={this.color} light={this.light} indicator={this.indicator}/></div>
        </div>
      )
    } else {
      if (Math.floor(this.type / 100) === 2 || Math.floor(this.type / 100) === 1 || Math.floor(this.type / 100) === 3 || Math.floor(this.type / 100) === 4) {
        return (
          <div>
            <div><Icon mode={this.mode} draw={this.draw} type={this.type} rotation={this.rotation} color={this.color} light={this.light} indicator={this.indicator} /></div>
            <div className="overlay" onClick={(e) => { e.stopPropagation() }}>
              <div className="modalContainer">
                <div className="modal-content">
                  <div>
                    <h2>Select tile type</h2>
                    {this.renderTypes()}
                    {(this.type%100 !== 0 && (Math.floor(this.type / 100) === 3 || Math.floor(this.type / 100) === 4)) &&
                    <div>
                    <h3>Additional turnout info</h3>
                    <p>
                      <label>Address: </label>
                      <input type="number" id="addr" min="0" max="2048" step="1" onChange={(e) => this.inputAddr(e)} defaultValue={this.addr?((this.addr & 0x0FFF)+1):0}></input>
                    </p>
                    <p onChange={(e) => this.inputProtocol(e)}>
                      <label>Protocol: </label>
                      <label><input type="radio" name="protocol" value="1" defaultChecked={this.addrrange(1)} />DCC</label>
                      <label><input type="radio" name="protocol" value="2" defaultChecked={this.addrrange(2)} />MM</label>
                    </p>
                    </div>
                    }
                    {(this.type === 201) &&
                    <div>
                    <h3>Additional signal info</h3>
                    <p>
                      <label>Address: </label>
                      <input type="number" id="addr" min="0" max="2048" step="1" onChange={(e) => this.inputAddr(e)} defaultValue={this.addr?((this.addr & 0x0FFF)+1):0}></input>
                    </p>
                    <p onChange={(e) => this.inputProtocol(e)}>
                      <label>Protocol: </label>
                      <label><input type="radio" name="protocol" value="1" defaultChecked={this.addrrange(1)} />DCC</label>
                      <label><input type="radio" name="protocol" value="2" defaultChecked={this.addrrange(2)} />MM</label>
                    </p>
                    </div>
                    }
                  </div>
                  <div className="modalEnd">
                    <div className="modalEnd-item" onClick={() => this.closeFunction(0)}>
                      <div>
                        <IconContext.Provider value={{size: '26px'}} >
                          <MdOutlineCancel />
                        </IconContext.Provider>
                      </div>
                      <div style={{margin: "4px"}}>Cancel</div>
                    </div>
                    <div className="modalEnd-item" onClick={() => this.closeFunction(1)}>
                      <div>
                        <IconContext.Provider value={{size: '26px'}} >
                          <MdCheckCircleOutline />
                        </IconContext.Provider>
                      </div>
                      <div style={{margin: "4px"}}>Accept</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (Math.floor(this.type / 100) === 5 || Math.floor(this.type / 100) === 6 || Math.floor(this.type / 100) === 9) {
        return (
          <div>
            <div><Icon mode={this.mode} draw={this.draw} type={this.type} rotation={this.rotation} color={this.color} light={this.light} /></div>
            <div className="overlay" onClick={(e) => { e.stopPropagation() }}>
              <div className="modalContainer">
                <div className="modal-content">
                  <div>
                    <h2>Select tile type</h2>
                    {this.renderTypes()}
                  </div>
                  <div className="modalEnd">
                    <div className="modalEnd-item" onClick={() => this.closeFunction(0)}>
                      <div>
                        <IconContext.Provider value={{size: '26px'}} >
                          <MdOutlineCancel />
                        </IconContext.Provider>
                      </div>
                      <div style={{margin: "4px"}}>Cancel</div>
                    </div>
                    <div className="modalEnd-item" onClick={() => this.closeFunction(1)}>
                      <div>
                        <IconContext.Provider value={{size: '26px'}} >
                          <MdCheckCircleOutline />
                        </IconContext.Provider>
                      </div>
                      <div style={{margin: "4px"}}>Accept</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div><Icon mode={this.mode} draw={this.draw} type={this.type} rotation={this.rotation} color={this.color} light={this.light} /></div>
            <div className="overlay" onClick={(e) => { e.stopPropagation() }}>
              <div className="modalContainer">
                <div className="modal-content">
                  <div>
                    <h2>Something went wrong</h2>
                    <p>Please click on "cancel" and try again. (Current type is {this.type}).</p>
                  </div>
                  <div className="modalEnd">
                    <div className="modalEnd-item" onClick={() => this.closeFunction(0)}>
                      <div>
                        <IconContext.Provider value={{size: '26px'}} >
                          <MdOutlineCancel />
                        </IconContext.Provider>
                      </div>
                      <div style={{margin: "4px"}}>Cancel</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderTypes() {
    if (Math.round(this.type / 100) === 2)
    {
      return (
        <div>
          <div className='modal-container5'>
            {this.items.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100).map((item, index) => (
              <div className={this.type === item ? 'modal-item modal-item-selected' : 'modal-item'} key={index} id={(index).toString()} onClick={(e) => this.itemClicked(e, index,0)}>
                <Icon mode={2} draw={this.draw} type={item} rotation={+this.rotation%2} color={this.color} flash={this.flash} />
                <div>{item}</div>
              </div>
            ))}
          </div>
          <div className='modal-container5'>
            {this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100).map((item, index) => (
              <div className={(this.type === item && this.rotation < 2) ? 'modal-item modal-item-selected' : 'modal-item'} key={index} id={(index).toString()} onClick={(e) => this.itemClicked(e, index,1)}>
                <Icon mode={2} draw={this.draw} type={item} rotation={+this.rotation%2} color={this.color} flash={this.flash} />
                <div>{item}</div>
              </div>
            ))}
          </div>
          <div className='modal-container5'>
            {this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100).map((item, index) => (
              <div className={(this.type === item && this.rotation >= 2) ? 'modal-item modal-item-selected' : 'modal-item'} key={index} id={(index).toString()} onClick={(e) => this.itemClicked(e, index,2)}>
                <Icon mode={2} draw={this.draw} type={item} rotation={+(this.rotation%2)+2} color={this.color} flash={this.flash} />
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }else{
      return (
        <div>
          <div className='modal-container5'>
            {this.items.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100).map((item, index) => (
              <div className={this.type === item ? 'modal-item modal-item-selected' : 'modal-item'} key={index} id={(index).toString()} onClick={(e) => this.itemClicked(e, index,0)}>
                <Icon mode={2} draw={this.draw} type={item} rotation={this.rotation} color={this.color} flash={this.flash} />
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  turnoutClicked(e: React.MouseEvent, id: number) {

  }

  addrrange(val: number) {
    if (val === ((this.addr&0x7000)>>12))
    {
      return true;
    }else{
      return false;
    }
  }

  async inputAddr(event: any) {
    if (Math.round(event.target.value) > 0 && Math.round(event.target.value) <= 0x1000) {
      if ((this.addr & 0xF000) === 0) {
        this.addr = 0x1000; // default to DCC
      }
      this.addr = Math.round(event.target.value) - 1 + (this.addr & 0xF000);
    }else{
      this.addr = 0;
    }
    this.forceUpdate();
  }

  async inputProtocol(event: any) {
    //console.log("protocol: "+event.target.value);
    if (Math.round(event.target.value) >= 0 && Math.round(event.target.value) <= 7) {
      this.addr = (Math.round(event.target.value)<<12) + (this.addr & 0x8FFF);
    }
    this.forceUpdate();
  }

  async itemClicked(e: React.MouseEvent, id: number, row: number) {
    //var div = document.getElementById(id.toString());
    let rotation = 0;
    if (row === 0) {
      console.log("clicked modal item: " + id + " " + this.items.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id]);
      this.type = this.items.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id];
      rotation = +(this.rotation%4);
    }else if (row === 1) {
      console.log("clicked modal item(1): " + id + " " + this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id]);
      this.type = this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id];
      rotation = +(this.rotation%2);
    }else{
      console.log("clicked modal item(2): " + id + " " + this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id]);
      this.type = this.items_dir.filter(n => n >= Math.round(this.type / 100) * 100 && n < (Math.round(this.type / 100) + 1) * 100)[id];
      rotation = +(this.rotation%2)+2;
    }
    this.rotation = rotation;
    this.forceUpdate();
  }

  closeFunction(val: number) {
    this.modal = 0;
    if (val === 0) {
      // restore old values
      this.type = this.type_backup;
      this.rotation = this.rotation_backup;
      if (this.type % 100 === 0) {
        this.addr = 0;
      } else {
        this.addr = this.addr_backup;
      }
    }else{
      console.log("store addr: "+this.addr);
      fetch("http://127.0.0.1:4545/turnout_set_type?ID=" + this.ID + "&TYPE=" + this.type + "&ADD=" + (+this.rotation + (this.indicator*4))).then(res => { }, err => { });
      if (Math.floor(this.type / 100) === 3 || Math.floor(this.type / 100) === 4 || this.type === 201) {
        fetch("http://127.0.0.1:4545/turnout_set_addr?ID=" + this.ID + "&ADDR=" + this.addr).then(res => { }, err => { });
      }
    }
    this.forceUpdate();
  }
}

export default Tile;


