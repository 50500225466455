
/*
IMPORTANT: ROTATE IS NEEDED TO LET THE MOUSE EVENT GO THROUGH THE GREEN OVERLAYER MARKER
*/

import React from 'react'

import icons from './PanelSymbols/icon.svg'

const Icon = (props: any) => {
  if ((props.type % 100) !== 0 || (Math.floor(props.mode/100) !== 1)) {
    let light = new Array(8).fill(0);
    let turnoutlight = new Array(2).fill(0);
    let signallight = new Array(5).fill(0);
    let signallightcolor = new Array(5).fill("");
    if ((props.mode === 0 || props.mode === 204) && props.draw) {
      for (let n = 0; n < 8; n++) {
        if ((props.light & (1 << n)) !== 0) {
          light[n] = n + 1;
        }
      }
    }
    let bg = "BG";
    if (props.mode === 2) {
      bg = "BGfill"; // necessary to show modal for selecting tile type
    }
    let type = props.type;
    let rotation = 90*props.rotation;

    // turnout locked in route indicator:
    if (Math.floor(props.type/100) === 3 && light[2]) {
      turnoutlight[0] = 3;
      light[2] = 0;
    }
    if (Math.floor(props.type/100) === 4 && light[6]) {
      turnoutlight[0] = 7;
      light[6] = 0;
    }

    if (props.type === 201) {
      // signal
      if (light[2]) {
        signallight[0] = 1;
        signallightcolor[0] = "LED-red";
        light[2] = 0;
      }
      if (light[3]) {
        signallight[0] = 2;
        signallightcolor[0] = "LED-green";
        light[3] = 0;
      }
    }

    let typenum = type;
    if (props.indicator === 1) {
      typenum = typenum + "a";
    }else if (props.indicator === 2) {
      typenum = typenum + "b";
    }else if (props.indicator === 3) {
      typenum = typenum + "c";
    }

    return (
      <svg
        transform={`rotate(${rotation}, 0, 0)`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`icon`}
        display="block"
        viewBox="0 0 260 260"
      >
        <use xlinkHref={`${icons}#${bg}`} className={`LED-grey`} />
        <use xlinkHref={`${icons}#${typenum}`} className={`LED-grey`} />
        <use xlinkHref={`${icons}#L${light[0]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[1]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[2]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[3]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[4]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[5]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[6]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#L${light[7]}`} className={`LED-white`} />
        <use xlinkHref={`${icons}#TL${turnoutlight[0]}`} className={`LED-yellow`} />
        <use xlinkHref={`${icons}#TL${turnoutlight[1]}`} className={`LED-yellow`} />
        <use xlinkHref={`${icons}#SL${signallight[0]}`} className={`${signallightcolor[0]}`} />
        <use xlinkHref={`${icons}#SL${signallight[1]}`} className={`${signallightcolor[1]}`} />
        <use xlinkHref={`${icons}#SL${signallight[2]}`} className={`${signallightcolor[2]}`} />
        <use xlinkHref={`${icons}#SL${signallight[3]}`} className={`${signallightcolor[3]}`} />
        <use xlinkHref={`${icons}#SL${signallight[4]}`} className={`${signallightcolor[4]}`} />
      </svg>
    )
  } else {
    // drawing
    let error = "";
    if (Math.floor(props.mode/100) !== 1) {
      if (props.type === 0) {
        error = "error";
      }
    }
    let draw = "";
    if (Math.floor(props.mode/100) === 1) {
      draw = "draw";
    }
    let elem = new Array(8).fill(0);
    for (let n = 0; n < 8; n++) {
      if ((props.draw & (1 << n)) !== 0) {
        elem[n] = n + 1;
      }
    }
    return (
      <svg
        transform={`rotate(0, 0, 0)`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`icon`}
        display="block"
        viewBox="0 0 260 260"
      >
        <use xlinkHref={`${icons}#${error}`} />
        <use xlinkHref={`${icons}#${draw}`} />
        <use xlinkHref={`${icons}#BG`} />
        <use xlinkHref={`${icons}#${elem[0]}`} />
        <use xlinkHref={`${icons}#${elem[1]}`} />
        <use xlinkHref={`${icons}#${elem[2]}`} />
        <use xlinkHref={`${icons}#${elem[3]}`} />
        <use xlinkHref={`${icons}#${elem[4]}`} />
        <use xlinkHref={`${icons}#${elem[5]}`} />
        <use xlinkHref={`${icons}#${elem[6]}`} />
        <use xlinkHref={`${icons}#${elem[7]}`} />
      </svg>
    )
  }
}

export default Icon

