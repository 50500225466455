import React from "react";
import Tile from "./Tile"
import { useState, useEffect, useRef } from "react";
import { drawbutton1, drawbutton2 } from "./TileButtons/TileButtons";
import { forwardRef, useImperativeHandle, Ref } from "react";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";
import { PiRectangleDuotone } from "react-icons/pi";
import { IconContext } from "react-icons";
import { MdOutlineCancel, MdCheckCircleOutline, MdCrop } from "react-icons/md";
import Settings from "./Settings"

interface PanelInput {
  parentFunction: Function,
  zoom: any
}

export interface RefType {
  setMode: (val: number) => void;
}

function Panel(props: PanelInput, ref: Ref<RefType>) {
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const [modal, setModal] = useState(0);
  const [oldmode, setOldmode] = useState(0);
  const [resizeLeft, setResizeLeft] = useState(0);
  const [resizeRight, setResizeRight] = useState(0);
  const [resizeTop, setResizeTop] = useState(0);
  const [resizeBottom, setResizeBottom] = useState(0);
  const [green_left, setGreen_left] = useState(0);
  const [green_top, setGreen_top] = useState(0);
  const [green_width, setGreen_width] = useState(0);
  const [green_height, setGreen_height] = useState(0);
  const [greenID, setGreenID] = useState(0);
  const [greenColor, setGreencolor] = useState("rgba(0,150,136,0.2)");
  const [paste, setPaste] = useState(0);

  const [serverDone, changeServerDone] = useState(0);

  function sleep(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  async function setMode(val: number) {
    for (let n = 0; n < y; n++) {
      for (let m = 0; m < x; m++) {
        let draw = refs.current[m + 100 * n]?.setMode(val);
//        if (Math.floor(oldmode/100) === 1) {
        if (draw >= 0) {
          await fetch("http://127.0.0.1:4545/turnout_set_draw?ID=" + (m + 100 * n) + "&DRAW=" + draw).then(res => { }, err => { });
        }
      }
    }
    fetch("http://127.0.0.1:4545/turnout_store").then(res => { }, err => { });
    setOldmode(val);
    if (val === 102) {
      // show resize modal
      setResizeLeft(0);
      setResizeRight(0);
      setResizeTop(0);
      setResizeBottom(0);
      setModal(1);
    }
    if (val === 101) {
      // auto-connect
      setModal(-1);
      for (let n = 0; n < y; n++) {
        for (let m = 0; m < x; m++) {
          let draw = refs.current[m + 100 * n]?.setMode(oldmode);
          if (draw >= 0)
          {
            await fetch("http://127.0.0.1:4545/turnout_set_draw?ID=" + (m + 100 * n) + "&DRAW=" + draw).then(res => { }, err => { });
          }
        }
      }
      //      await fetch("http://127.0.0.1:4545/panel.json").then(response => response.json()).then(res => { }, err => { });
      await fetch("http://127.0.0.1:4545/turnout_auto_connect").then(res => { }, err => { });
      console.log("auto: update " + oldmode);
      setModal(0);
    }
    if (val !== 201 && val !== 202 && val !== 203) {
      // NOT any of cut, copy and paste
      setGreen_left(0);
      setGreen_width(0);
      setGreen_top(0);
      setGreen_height(0);
      setGreenID(0);
      setGreencolor("rgba(0,150,136,0.2)");
    }
    if (val === 203)
    {
      // paste: get size
      fetch("http://127.0.0.1:4545/panel_paste_size.json").then(response => response.json()).then(res => {
        setPaste(res.xy);
      }, err => { });
    }
    if (val === 2) {
      // sync turnouts and signals
      await fetch("http://127.0.0.1:4545/sync_all").then(res => {
        props.parentFunction(0);
        loadPanel();
      }, err => { });
    }
    if (val === 3) {
      // release locks and routes
      await fetch("http://127.0.0.1:4545/release_all").then(res => {
        props.parentFunction(0);
        loadPanel();
      }, err => { });
    }
    if (val === 300 && serverDone === 1) {
      changeServerDone(2);
    }else if (serverDone === 2) {
      changeServerDone(1);
      loadPanel();
    }
  };

  useImperativeHandle(ref, () => ({ setMode }));

  //    let x = props.x;
  //    let y = props.y;
  let ledphase = 1;
//  let types: any[][] = new Array(y).fill(false).map(() => new Array(x).fill(0));
//  let states: any[][] = new Array(y).fill(false).map(() => new Array(x).fill(0));
  let refs: any = useRef(new Array(2000));
  const [matrix, setMatrix] = useState(Array.from({ length: x }, () => Array.from({ length: y }, () => null)));

  function pullJSON() {
    fetch("http://127.0.0.1:4545/light_data.json").then(response => response.json()).then(res => {
      let len = res.light_data.length;
      if ((len % 2) === 0 && len) {
        len = len / 2;
        for (let n = 0; n < len; n++) {
          let element = res.light_data[0 + 2 * n];
          //console.log("light for element: " + element);
          let opcode = (res.light_data[1 + 2 * n] >> 12) % 16;
          if (opcode === 1 && element < 2000) {
            let color = (res.light_data[1 + 2 * n] >> 9) % 8;
            let flash = (res.light_data[1 + 2 * n] >> 6) % 8;
            let led1 = (res.light_data[1 + 2 * n] >> 3) % 8;
            let led2 = (res.light_data[1 + 2 * n] >> 0) % 8;
            //console.log(element+" "+color+" "+flash+" "+led1+" "+led2);
            /*
            flash	LED1	LED2	use-case
            0	off	off	clear route of track elements
            1	on	off	turnout position sat
            2	on	on	set route of track elements
            3	1Hz	off	turnout set new position
            4	1Hz	!1Hz 	turnout set new position (UK)
            5	1Hz	1Hz	turnout error
            6	1/2Hz	off	slow flashing
            7	one combination left

            0 off
            1 on
            2 1/2Hz
            3 1Hz
            4 !1Hz
            */
            let flash1 = 0;
            let flash2 = 0;
            if (flash === 1) { flash1 = 1; }
            if (flash === 2) { flash1 = 1; flash2 = 1; }
            if (flash === 3) { flash1 = 3; }
            if (flash === 4) { flash1 = 3; flash2 = 4; }
            if (flash === 5) { flash1 = 3; flash2 = 3; }
            if (flash === 6) { flash1 = 2; }
            refs.current[element]?.ledUpdate(led1, color, flash1);
            if (led1 !== led2) {
              refs.current[element]?.ledUpdate(led2, color, flash2);
            }
          }
          if (opcode === 1 && element === 2000) {
            // clear all light
            for (let n = 0; n < 2000; n++) {
              for (let m = 0; m < 8; m++) {
                refs.current[n]?.ledUpdate(m, 0, 0);
              }
            }
          }
        }
      }
    }, err => { });
    return
  }

  async function loadPanel() {
    await fetch("http://127.0.0.1:4545/panel.json").then(response => response.json()).then(res => {
      setX(res.panel_x);
      setY(res.panel_y);
      document.documentElement.style.setProperty("--colNum", (x).toString());
      document.documentElement.style.setProperty("--rowNum", (y).toString());
      setMatrix(Array.from({ length: y }, () => Array.from({ length: x }, () => null)));
      changeServerDone(1);
    }, err => { console.log("panel load error: " + err) });
    await sleep(1000);
    await fetch("http://127.0.0.1:4545/panel.json").then(response => response.json()).then(res => {
      console.log("xy: "+x+" "+y);
      if (x && y) {
        for (let n = 0; n < y; n++) {
          for (let m = 0; m < x; m++) {
            refs.current[m + 100 * n]?.setup(m + n * 100, res.panel_draw[m + n * x], res.panel_type[m + n * x], res.panel_rotation[m + n * x], res.panel_addr[m + n * x], res.panel_indicator[m + n * x]);
          }
        }
      }
    }, err => { console.log("panel load error: " + err) });
    await fetch("http://127.0.0.1:4545/app_restarted").then(res => { }, err => { });
  }

  async function resize(val: number) {
    if (val === 0) {
      // auto crop
      let xoff = (+x + resizeLeft + resizeRight) * 1000 + resizeLeft;
      let yoff = (+y + resizeTop + resizeBottom) * 1000 + resizeTop;
      console.log("resize: " + xoff + " " + yoff + " " + x + " " + y);
      await fetch("http://127.0.0.1:4545/panel_resize?X=" + xoff + "&Y=" + yoff).then(res => { }, err => { });
    } else if (val === 1) {
      let xoff = (+x + resizeLeft + resizeRight) * 1000 + resizeLeft;
      let yoff = (+y + resizeTop + resizeBottom) * 1000 + resizeTop;
      console.log("resize: " + xoff + " " + yoff + " " + x + " " + y);
      await fetch("http://127.0.0.1:4545/panel_resize?X=" + xoff + "&Y=" + yoff).then(res => { }, err => { });
    }else{
      // cancel
    }
    await loadPanel();
    await props.parentFunction(100);
    setModal(0);
  }

  useEffect(() => {
    loadPanel();
    //Implementing the setInterval method
    const interval = setInterval(() => {
      pullJSON();
    }, 200);

    const interval2 = setInterval(() => {
      for (let n = 0; n < y; n++) {
        for (let m = 0; m < x; m++) {
          refs.current[m + 100 * n]?.ledPhase(ledphase);
        }
      }
      if (++ledphase >= 5) {
        ledphase = 1;
      }
    }, 500);

    //Clearing the interval
    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [x, y, modal]);

  return (
    <div>
      <div className="overlaymarkerGreen" style={{backgroundColor: `${greenColor}`, left: `${green_left}px`, top: `${green_top}px`, width: `${green_width}px`, height: `${green_height}px`}}>
      </div>
      {(serverDone === 1) &&
        <div id="panel" className='panel-container' style={{ zIndex: 5 }}>
          {matrix.map((row, index_row) => (row.map((val, index_col) => (
            <div style={{width: props.zoom+"px", height: props.zoom+"px"}} className='panel-item' key={index_row * 100 + index_col} id={(index_row * 100 + index_col).toString()} onClick={(e) => tileClicked(e, index_row * 100 + index_col, index_col, index_row)} onMouseOver={(e) => mouseOver(e, index_row * 100 + index_col, index_col, index_row)}>
              <Tile ref={(element) => refs.current[index_row * 100 + index_col] = element} />
            </div>
          ))))}
        </div>
      }
      {(serverDone === 2) &&
        <div>
          <Settings />
        </div>
      }
      {(modal === 1) &&
        <div className="overlay" onClick={(e) => { e.stopPropagation() }}>
          <div className="modalContainer">
            <div className="modal-content">
              <div>
                <h2>Resize</h2>
                <div className="resize-modal">
                  <div></div>
                  <div className="resize-modal-item">
                    <div onClick={() => { setResizeTop(resizeTop-1) }}><IconContext.Provider value={{size: '26px'}} ><FiMinusSquare /></IconContext.Provider></div>
                    <div style={{margin: "4px"}}>{resizeTop}</div>
                    <div onClick={() => { setResizeTop(resizeTop+1) }}><IconContext.Provider value={{size: '26px'}} ><FiPlusSquare /></IconContext.Provider></div>
                  </div>
                  <div></div>
                  <div className="resize-modal-item">
                    <div onClick={() => { setResizeLeft(resizeLeft-1) }}><IconContext.Provider value={{size: '26px'}} ><FiMinusSquare /></IconContext.Provider></div>
                    <div style={{margin: "4px"}}>{resizeLeft}</div>
                    <div onClick={() => { setResizeLeft(resizeLeft+1) }}><IconContext.Provider value={{size: '26px'}} ><FiPlusSquare /></IconContext.Provider></div>
                  </div>
                  <div>
                    <IconContext.Provider value={{size: '200px'}} >
                      <PiRectangleDuotone />
                    </IconContext.Provider>
                  </div>
                  <div className="resize-modal-item">
                    <div onClick={() => { setResizeRight(resizeRight-1) }}><IconContext.Provider value={{size: '26px'}} ><FiMinusSquare /></IconContext.Provider></div>
                    <div style={{margin: "4px"}}>{resizeRight}</div>
                    <div onClick={() => { setResizeRight(resizeRight+1) }}><IconContext.Provider value={{size: '26px'}} ><FiPlusSquare /></IconContext.Provider></div>
                  </div>
                  <div></div>
                  <div className="resize-modal-item">
                    <div onClick={() => { setResizeBottom(resizeBottom-1) }}><IconContext.Provider value={{size: '26px'}} ><FiMinusSquare /></IconContext.Provider></div>
                    <div style={{margin: "4px"}}>{resizeBottom}</div>
                    <div onClick={() => { setResizeBottom(resizeBottom+1) }}><IconContext.Provider value={{size: '26px'}} ><FiPlusSquare /></IconContext.Provider></div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="modalEnd">
                <div className="modalEnd-item" onClick={() => resize(0)}>
                  <div>
                    <IconContext.Provider value={{size: '26px'}} >
                      <MdCrop />
                    </IconContext.Provider>
                  </div>
                  <div style={{margin: "4px"}}>Auto-crop</div>
                </div>
                <div className="modalEnd-item" onClick={() => resize(2)}>
                  <div>
                    <IconContext.Provider value={{size: '26px'}} >
                      <MdOutlineCancel />
                    </IconContext.Provider>
                  </div>
                  <div style={{margin: "4px"}}>Cancel</div>
                </div>
                <div className="modalEnd-item" onClick={() => resize(1)}>
                  <div>
                    <IconContext.Provider value={{size: '26px'}} >
                      <MdCheckCircleOutline />
                    </IconContext.Provider>
                  </div>
                  <div style={{margin: "4px"}}>Accept</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )

  function tileClicked(e: React.MouseEvent, id: number, col: number, row: number) {
    var div = document.getElementById(id.toString());
    var divframe = document.getElementById("content");
    var x = (e.clientX - (divframe?.offsetLeft || 0) + (divframe?.scrollLeft || 0) - (div?.offsetLeft || 0)) / (div?.offsetWidth || 0) * 130;
    if (x < 0) { x = 0; }
    if (x > 130) { x = 130; }
    var y = (e.clientY - (divframe?.offsetTop || 0) + (divframe?.scrollTop || 0) - (div?.offsetTop || 0)) / (div?.offsetHeight || 0) * 130;
    if (y < 0) { y = 0; }
    if (y > 130) { y = 130; }

    // x and y are values between 0 and 130 for position clicked in tile, id is the number of the tile
    //console.log("tile: "+Math.round(div?.offsetTop || 0)+" "+(divframe?.scrollTop || 0)+" "+e.clientY+" "+Math.round(e.clientY-92+(divframe?.scrollTop || 0)))
    console.log(Math.round((divframe?.offsetTop || 0)))
    //console.log(Math.round(x)+" "+Math.round(y)+" id: "+id);
    //console.log(getButton(x, y));
    refs.current[id].drawingButton(getButton(x, y));

    if (oldmode === 201) {
      if (greenID) {
        // second click cut
        fetch("http://127.0.0.1:4545/panel_cut?ID1=" + (+greenID - 1) + "&ID2=" + id).then(res => { }, err => { });
        //setMode(0);
        props.parentFunction(200);
        loadPanel();
      } else {
        // first click cut
        setGreenID(id + 1);
      }
    }
    if (oldmode === 202) {
      if (greenID) {
        // second click copy
        fetch("http://127.0.0.1:4545/panel_copy?ID1=" + (+greenID - 1) + "&ID2=" + id).then(res => { }, err => { });
        //setMode(0);
        props.parentFunction(200);
        loadPanel();
      } else {
        // first click copy
        setGreenID(id + 1);
      }
    }
    if (oldmode === 203) {
      // paste
      fetch("http://127.0.0.1:4545/panel_paste?ID=" + id).then(res => { }, err => { });
      //setMode(0);
      props.parentFunction(200);
      loadPanel();
    }
  }

  function mouseOver(e: React.MouseEvent, id: number, col: number, row: number) {
    var div = null;
    var x1a = 0;
    var x2a = 0;
    var y1a = 0;
    var y2a = 0;
    var x1b = 0;
    var x2b = 0;
    var y1b = 0;
    var y2b = 0;
    var x1 = 0;
    var x2 = 0;
    var y1 = 0;
    var y2 = 0;
    if (oldmode === 201 || oldmode === 202) {
      setGreencolor("rgba(0,150,136,0.2)");
      if (greenID) {
        div = document.getElementById(id.toString());
        x1a = (div?.offsetLeft || 0);
        x2a = (div?.offsetLeft || 0) + (div?.offsetWidth || 0);
        y1a = (div?.offsetTop || 0);
        y2a = (div?.offsetTop || 0) + (div?.offsetHeight || 0);
        div = document.getElementById((+greenID - 1).toString());
        x1b = (div?.offsetLeft || 0);
        x2b = (div?.offsetLeft || 0) + (div?.offsetWidth || 0);
        y1b = (div?.offsetTop || 0);
        y2b = (div?.offsetTop || 0) + (div?.offsetHeight || 0);
        x1 = Math.min(x1a, x2a, x1b, x2b);
        x2 = Math.max(x1a, x2a, x1b, x2b);
        y1 = Math.min(y1a, y2a, y1b, y2b);
        y2 = Math.max(y1a, y2a, y1b, y2b);
        //console.log("mouse: "+oldmode+" "+id+" "+x1+" "+x2+" "+y1+" "+y2);
        setGreen_left(x1);
        setGreen_width(x2 - x1);
        setGreen_top(y1);
        setGreen_height(y2 - y1);
      } else {
        div = document.getElementById(id.toString());
        x1 = (div?.offsetLeft || 0);
        x2 = (div?.offsetLeft || 0) + (div?.offsetWidth || 0);
        y1 = (div?.offsetTop || 0);
        y2 = (div?.offsetTop || 0) + (div?.offsetHeight || 0);
        //console.log("mouse: "+oldmode+" "+id+" "+x1+" "+x2+" "+y1+" "+y2);
        setGreen_left(x1);
        setGreen_width(x2 - x1);
        setGreen_top(y1);
        setGreen_height(y2 - y1);
      }
    }
    if (oldmode === 203)
    {
      var xmax = (id%100) + (paste%100) - 1;
      var ymax = Math.floor(id/100) + Math.floor(paste/100) - 1;

      div = document.getElementById(id.toString());
      x1a = (div?.offsetLeft || 0);
      x2a = (div?.offsetLeft || 0) + (div?.offsetWidth || 0);
      y1a = (div?.offsetTop || 0);
      y2a = (div?.offsetTop || 0) + (div?.offsetHeight || 0);
      var idmax = Math.min(xmax, x-1) + Math.min(ymax,y-1)*100;
      var oversize = 0;
      if (xmax >= x || ymax >= y) {
        oversize = 1;
      }
      div = document.getElementById(idmax.toString());
      x1b = (div?.offsetLeft || 0);
      x2b = (div?.offsetLeft || 0) + (div?.offsetWidth || 0);
      y1b = (div?.offsetTop || 0);
      y2b = (div?.offsetTop || 0) + (div?.offsetHeight || 0);
      //console.log("mouse: "+x1b+" "+x2b+" "+y1b+" "+y2b);
      x1 = Math.min(x1a, x2a, x1b, x2b);
      x2 = Math.max(x1a, x2a, x1b, x2b);
      y1 = Math.min(y1a, y2a, y1b, y2b);
      y2 = Math.max(y1a, y2a, y1b, y2b);
      setGreen_left(x1);
      setGreen_width(x2 - x1);
      setGreen_top(y1);
      setGreen_height(y2 - y1);

      if (oversize)
      {
        setGreencolor("rgba(255,150,136,0.2)");
      }else{
        setGreencolor("rgba(0,150,136,0.2)");
      }
      //console.log("paste: "+xmax+" "+x+" "+ymax+" "+y+" "+id+" "+idmax);
    }
  }
}

function getButton(x: number, y: number) {
  let element0 = Math.round(x) + Math.round(y) * 130;
  let element90 = Math.round(y) + Math.round(130 - x) * 130;
  let element180 = Math.round(130 - x) + Math.round(130 - y) * 130;
  let element270 = Math.round(130 - y) + Math.round(x) * 130;
  if (drawbutton1[element0] === true) {
    return 0;
  } else if (drawbutton2[element0] === true) {
    return 1;
  } else if (drawbutton1[element90] === true) {
    return 2;
  } else if (drawbutton2[element90] === true) {
    return 3;
  } else if (drawbutton1[element180] === true) {
    return 4;
  } else if (drawbutton2[element180] === true) {
    return 5;
  } else if (drawbutton1[element270] === true) {
    return 6;
  } else if (drawbutton2[element270] === true) {
    return 7;
  } else {
    return -1;
  }
}

export default forwardRef(Panel);

/* generate button position data:
  clear && convert test.svg -colorspace gray -compress none -resize 140x100 -threshold 50% pnm:-
*/