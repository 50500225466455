import React from "react"
import './App.css';
import { useState, useEffect, useRef } from "react";
import Panel, { RefType } from './Panel'
import { BiCut, BiCopy, BiPaste, BiSlider } from "react-icons/bi";
import { MdDraw, MdAutoFixHigh, MdDashboardCustomize, MdInvertColors, MdToggleOff, MdTrain, MdCrop, MdSync, MdLockOpen, MdSettings, MdOutlineSlideshow } from "react-icons/md";
import { PiEraserFill } from "react-icons/pi";
import { IconContext } from "react-icons";
import Logo from './logo.png';
import { TbPlugConnectedX, TbPlugConnected } from "react-icons/tb";
import { MdFileDownload, MdOutlineZoomIn, MdOutlineZoomOut, MdUpdate, MdOutlineHelpOutline } from "react-icons/md";
import packageJson from '../package.json';

export default function App() {
  const panelRef = useRef<RefType>(null);
  const intervalRef = useRef<any>();
  const [zoom, setZoomVal] = React.useState(Math.max(Math.min(JSON.parse((localStorage.getItem('zoom') || '100')),200),30));
  const [engine, setEngine] = React.useState(null);
  let autorundone = 0;

  function openHelp () {
    window.open("https://www.railmagic.com/manual.pdf", "_blank", "noreferrer");
  }

  const parentSetMode = (val: number) => {
    console.log("parent: "+val);
    setMode(val);
  }

  function setMode(val: number) {
    if (val === mode && (mode%100) === 0) {
      val = 0; // leave menu
    }
    if (val === mode && (mode%100) !== 0) {
      val = Math.floor(mode/100)*100; // leave submenu
    }
    console.log("setmode: "+mode+" > "+val);
    panelRef.current?.setMode(val);
    changeMode(val);
  }

  const [server, setServer] = useState(0);
  const [mode, changeMode] = useState(0);

  const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

  function startZoomIn() {
    if (intervalRef.current) return;
    setZoomVal((zoom:any) => zoom+1);
    intervalRef.current = setInterval(() => {
      setZoomVal((zoom:any) => zoom+1);
    }, 100);
  };

  function stopZoomIn() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      localStorage.setItem('zoom',JSON.stringify(zoom));
    }
  };

  function startZoomOut() {
    if (intervalRef.current) return;
    setZoomVal((zoom:any) => zoom-1);
    intervalRef.current = setInterval(() => {
      setZoomVal((zoom:any) => zoom-1);
    }, 100);
  };

  function stopZoomOut() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      localStorage.setItem('zoom',JSON.stringify(zoom));
    }
  };

  function getServer() {
    fetch("http://127.0.0.1:4545/").then(response => response.json()).then(res => {
      setEngine(res.version.slice(10));

      let vercode = (+res.major*100*100) + (+res.minor*100) + (+res.build);
      if (vercode >= 10007) {
        // Engine version expected
        setServer(1);
      }else{
        // Engine version older than expected
        setServer(3);
      }

      let SSID = localStorage.getItem('SSID');
      let user = localStorage.getItem('user');
      let first_startup = 0;
      if (SSID === null)
      {
        first_startup = 1;
        const genRanHex = (size:number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
        localStorage.setItem('SSID',genRanHex(32));
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({Realm: packageJson.version, Engine: res.major+'.'+res.minor+'.'+res.build, SSID: SSID, Init: first_startup, User: user })
      };
      if (user !== "admin") {
        fetch("https://www.railmagic.com/api/realm/check_for_update.php", requestOptions).then(response => response.json()).then(res => {
        }, err => {});
      }
    }, err => {
      if (JSON.parse(localStorage.getItem('autorun') || '0') === '1' && !autorundone)
      {
        window.open("railmagic:\\run",'_self');
        autorundone = 1;
        retryServer();
      }
      setServer(2);
    })
  }

  function autoConnect(event: any) {
    if (event.target.checked === true) {
      localStorage.setItem('autorun',JSON.stringify('1'));
    }else{
      localStorage.setItem('autorun',JSON.stringify('0'));
    }
  }

  async function retryServer() {
    setServer(0);
    await sleep(4000);
    getServer();
  }

  useEffect(() => {
    getServer();
    return () => {
      stopZoomIn();
    }
  }, [])

  return (
    <div className="wrapper">
      <div className="header menu-container unselectable">

<div className="menu-item">
  <div className="menu-vertical"></div>
</div>

{//MENU
}
<div className="menu-item" onClick={(e) => setMode(100)} style={{color: Math.floor(mode/100) === 1?'#009688':''}}>
  <div className="menu-item-part">
    <IconContext.Provider value={{size: '60px'}} >
      <MdDraw />
    </IconContext.Provider>
  </div>
  <div className="menu-item-part">
    Design
  </div>
</div>
<div className="menu-item" onClick={(e) => setMode(200)} style={{color: Math.floor(mode/100) === 2?'#009688':''}}>
  <div className="menu-item-part">
    <IconContext.Provider value={{size: '60px'}} >
      <MdDashboardCustomize />
    </IconContext.Provider>
  </div>
  <div className="menu-item-part">
    Configure
  </div>
</div>

<div className="menu-item">
  <div className="menu-vertical"></div>
</div>

{//SUBMENU 0 (these functions might open a modal without changing the mode)
}
{(mode < 100 || mode === 300) &&
<div className="menu-item" onClick={(e) => setMode(300)} style={{color: Math.floor(mode/100) === 3?'#009688':''}}>
<div className="menu-item-part">
  <IconContext.Provider value={{size: '60px'}} >
    <MdSettings />
  </IconContext.Provider>
</div>
<div className="menu-item-part">
  Settings
</div>
</div>
}

{//SUBMENU 100
}
{(mode >= 100 && mode < 200) &&
<div className="menu-item" onClick={(e) => setMode(101)}>
  <div className="menu-item-part">
    <IconContext.Provider value={{size: '60px'}} >
      <MdAutoFixHigh />
    </IconContext.Provider>
  </div>
  <div className="menu-item-part">
    Auto-connect
  </div>
</div>
}
{(mode >= 100 && mode < 200) &&
<div className="menu-item" onClick={(e) => setMode(102)}>
  <div className="menu-item-part">
    <IconContext.Provider value={{size: '60px'}} >
      <MdCrop />
    </IconContext.Provider>
  </div>
  <div className="menu-item-part">
    Resize
  </div>
</div>
}
{(mode >= 100 && mode < 200) &&
  <div className="menu-item" onClick={(e) => setMode(103)} style={{color: mode === 103?'#009688':''}}>
  <div className="menu-item-part">
    <IconContext.Provider value={{size: '60px'}} >
      <PiEraserFill />
    </IconContext.Provider>
  </div>
  <div className="menu-item-part">
    Erase
  </div>
</div>
}
{//SUBMENU 200
}
{(mode >= 200 && mode < 300) &&
<div className="menu-item">
  <div className="submenu-container">
    <div className="submenu-item" onClick={(e) => setMode(201)} style={{color: mode === 201?'#009688':''}}>
      <IconContext.Provider value={{size: '26px'}} >
        <BiCut /> Cut
      </IconContext.Provider>
    </div>
    <div className="submenu-item" onClick={(e) => setMode(202)} style={{color: mode === 202?'#009688':''}}>
      <IconContext.Provider value={{size: '26px'}} >
        <BiCopy /> Copy
      </IconContext.Provider>
    </div>
    <div className="submenu-item" onClick={(e) => setMode(203)} style={{color: mode === 203?'#009688':''}}>
      <IconContext.Provider value={{size: '26px'}} >
        <BiPaste /> Paste
      </IconContext.Provider>
    </div>
  </div>
</div>
}
{(mode >= 200 && mode < 300) &&
<div className="menu-item">
  <div className="submenu-container">
    <div className="submenu-item" onClick={(e) => setMode(204)} style={{color: mode === 204?'#009688':''}}>
      <IconContext.Provider value={{size: '26px'}} >
        <MdInvertColors /> Invert clear/set
      </IconContext.Provider>
    </div>
    <div className="submenu-item" onClick={(e) => setMode(205)} style={{color: mode === 205?'#009688':''}}>
      <IconContext.Provider value={{size: '26px'}} >
        <MdToggleOff /> Toggle indicators
      </IconContext.Provider>
    </div>
  </div>
</div>
}

{// end of SUBMENUs
}
<div className="menu-item">
  <div className="menu-vertical"></div>
</div>

<div className="menu-item menu-item-right">
  <img src={Logo} alt="Railmagic logo"></img>
</div>
</div>
<div id="content" className="content unselectable">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="contentcenter">
        {(server === 1) &&
        <div style={{ width: "100%", backgroundColor: "#999", zIndex: -10 }} className="unselectable" key="panel" id="panel">
          <Panel ref={panelRef} parentFunction={parentSetMode} zoom={zoom} />
        </div>
      }
      {(server === 2) &&
        <div style={{ backgroundColor: "#999" }}>
          <div className="overlay unselectable" onClick={(e) => { e.stopPropagation() }}>
            <div className="modalContainer">
              <div className="modal-content">
                <div>
                  <h2>Disconnected</h2>
                  <p>
                    <IconContext.Provider value={{size: '52px'}} >
                      <TbPlugConnectedX />
                    </IconContext.Provider>
                  </p>
                  <p>
                    This application consists of two pieces of software. The Railmagic Realm which you are currently using and the Railmagic Engine. It seems as the Railmagic Engine is not running at the moment.
                  </p>
                  <p>
                    Start the Railmagic Engine and click on "retry connecting". If you do not have the Railmagic Engine installed yet, the latest version can be downloaded by clicking on "download Railmagic Engine".
                  </p>
                </div>
                <div className="modalEnd">
                  <div className="modalEnd-item" onClick={() => retryServer()}>
                    <div>
                      <IconContext.Provider value={{size: '26px'}} >
                        <MdFileDownload />
                      </IconContext.Provider>
                    </div>
                    <a href={packageJson.homepage+"/RailmagicEngine.zip"} download="Railmagic Engine" target="_blank" rel="noreferrer"><div style={{margin: "4px"}}>Download Railmagic Engine</div></a>
                  </div>
                  <div className="modalEnd-item" onClick={() => retryServer()}>
                    <div>
                      <IconContext.Provider value={{size: '26px'}} >
                        <TbPlugConnected />
                      </IconContext.Provider>
                    </div>
                    <div style={{margin: "4px"}}>Retry connecting</div>
                  </div>
                </div>
                <p onChange={(e) => autoConnect(e)}>
                  <label><input type="checkbox" defaultChecked={JSON.parse(localStorage.getItem('autorun') || '0') === '1'}></input>Automatically run the Railmagic Engine at start</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      }
      {(server === 3) &&
        <div style={{ backgroundColor: "#999" }}>
          <div className="overlay unselectable" onClick={(e) => { e.stopPropagation() }}>
            <div className="modalContainer">
              <div className="modal-content">
                <div>
                  <h2>Update necessary</h2>
                  <p>
                    <IconContext.Provider value={{size: '52px'}} >
                      <MdUpdate />
                    </IconContext.Provider>
                  </p>
                  <p>
                    The Railmagic Realm version needs a newer version of the Railmagic Engine.
                  </p>
                  <p>
                    The latest version can be downloaded by clicking on "download Railmagic Engine". It will automatically replace the older version.
                  </p>
                  <p>
                    After the installation has completed, you can click on "retry connecting".
                  </p>
                </div>
                <div className="modalEnd">
                  <div className="modalEnd-item" onClick={() => retryServer()}>
                    <div>
                      <IconContext.Provider value={{size: '26px'}} >
                        <MdFileDownload />
                      </IconContext.Provider>
                    </div>
                    <a href={packageJson.homepage+"/RailmagicEngine.zip"} download="Railmagic Engine" target="_blank" rel="noreferrer"><div style={{margin: "4px"}}>Download Railmagic Engine</div></a>
                  </div>
                  <div className="modalEnd-item" onClick={() => retryServer()}>
                    <div>
                      <IconContext.Provider value={{size: '26px'}} >
                        <TbPlugConnected />
                      </IconContext.Provider>
                    </div>
                    <div style={{margin: "4px"}}>Retry connecting</div>
                  </div>
                </div>
                <p onChange={(e) => autoConnect(e)}>
                  <label><input type="checkbox" defaultChecked={JSON.parse(localStorage.getItem('autorun') || '0') === '1'}></input>Automatically run the Railmagic Engine at start</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      }
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="footer footer-container unselectable">

<div className="footer-item">
        <div style={{padding: "5px"}}>Realm {packageJson.version}, {engine}</div>
        </div>
        <div></div>
        <div className="footer-item" style={{padding: "3px"}}>
          <div onClick={openHelp}>
            <IconContext.Provider value={{size: '26px'}} >
              <MdOutlineHelpOutline />
            </IconContext.Provider>
          </div>
        </div>
        <div className="footer-item" style={{padding: "3px"}}>
          <div onMouseDown={startZoomOut} onMouseUp={stopZoomOut} onMouseLeave={stopZoomOut}>
            <IconContext.Provider value={{size: '26px'}} >
              <MdOutlineZoomOut />
            </IconContext.Provider>
          </div>
        </div>
        <div className="footer-item" style={{padding: "3px"}}>
          <div onMouseDown={startZoomIn} onMouseUp={stopZoomIn} onMouseLeave={stopZoomIn}>
            <IconContext.Provider value={{size: '26px'}} >
              <MdOutlineZoomIn />
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
}
